<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="row mb-2">
        <div class="col-12">
          <h2 class="pr-1 mb-0">{{ $route.meta.pageTitle }} #{{ $route.params.id }}</h2>
        </div>
      </div>
      <div class="content-body">
        <section>
          <div class="row">
            <div class="col-12 col-xl-9 col-md-8">
              <div class="card">
                <div class="card-body">
                  <SizeSelector
                      v-if="sizeOption && !loading"
                      @refresh="updateSize"
                      :option="sizeOption"
                      :init-value="form.size"
                  />
                  <div class="row mt-2">
                    <div class="col-3" v-if="labelSize">
                      <div class="form-group">
                        <label for="brand" class="d-block">Размер на бирке</label>
                        <div>
                          <input class="form-control" v-model="form.label_size"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for="brand" class="d-block">Вес</label>
                        <div>
                          <input class="form-control" v-model="form.weight"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for="brand" class="d-block">Кол-во в комплекте</label>
                        <div>
                          <input class="form-control" v-model="form.qty"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for="brand" class="d-block">Состояние</label>
                        <div>
                          <v-select v-model="form.condition" :clearable="false" :options="conditions" label="name" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Measurements v-if="measurements && !loading" :options="measurements" @update="updateMeasurements" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 invoice-actions">
              <div class="card">
                <div class="card-body">
                  <button class="btn btn-primary btn-block" @click="updateSpecification">Сохранить</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import SizeSelector from '@/views/components/SizeSelector.vue';
import Measurements from '@/views/components/Measurements.vue';

export default {
  name: 'SpecificationUpdate',
  components: { vSelect, SizeSelector, Measurements },
  data() {
    return {
      specification: null,
      form: {
        size: null,
        label_size: null,
        qty: 1,
        weight: null,
        condition: null,
      },
      loading: true,
      conditions: [],
      measurements: null,
      specificationOptions: [],
      sizeOption: null,
    };
  },
  async mounted() {
    await this.getSpecification();
    await this.getConditions();
    this.form.condition = { ...this.conditions[1] };
    await this.getDictionaries();
    this.setValues();
    this.loading = false;
  },
  methods: {
    async getSpecification() {
      this.specification = (await this.$api.productSpecifications.get(this.$route.params.id)).data;
    },
    async getConditions() {
      this.conditions = (await this.$api.conditions.list()).data;
    },
    async getDictionaries() {
      this.specificationOptions = (await this.$api.products.specificationOptions(this.specification.product_id)).data;
      this.sizeOption = this.specificationOptions.find((option) => option.slug === 'size');
      this.measurements = this.specificationOptions
          .filter((option) => option.product_group_id === 5)
          .reduce((grouped, item) => {
            const { slug } = item;

            const updatedGrouped = { ...grouped };

            if (!updatedGrouped[slug]) {
              updatedGrouped[slug] = [];
            }
            updatedGrouped[slug] = {
              name: item.name,
              value: null,
            };

            return updatedGrouped;
          }, null);
    },
    setValues() {
      if (this.sizeOption) {
        this.form.size = this.sizeOption.values.find((value) => value.id === this.specification.size_id);
      }
      const { dictionaries } = this.specification;

      this.form.label_size = dictionaries.find((dictionary) => dictionary.specification_option_id === 2)?.description;
      this.form.weight = dictionaries.find((dictionary) => dictionary.specification_option_id === 21)?.description;
      this.form.qty = dictionaries.find((dictionary) => dictionary.specification_option_id === 12)?.description;
      const condition = dictionaries.find((dictionary) => dictionary.specification_option_id === 22);
      this.form.condition = this.conditions.find((conditionValue) => conditionValue.id === condition.dictionary_id);

      if (this.measurements) {
        const measurements = dictionaries.filter((option) => option.specification_option.product_group_id === 5);

        measurements.forEach((item) => {
          if (this.measurements[item.specification_option.slug]) {
            this.measurements[item.specification_option.slug].value = item.description;
          }
        });
      }
    },
    updateSize(form, slug) {
      this.form.size = form;

      if (slug === 'size') {
        this.form.label_size = form.name;
      }
    },
    updateMeasurements(form) {
      this.form.measurements = form;
    },
    async updateSpecification(e) {
      const error = this.validate();
      if (error) {
        this.$bvToast.toast(error, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
        e.preventDefault();
        return;
      }

      const data = { ...this.form };
      data.size_id = data.size ? data.size.id : null;
      data.condition_id = data.condition.id;
      delete data.size;
      delete data.condition;
      try {
        await this.$api.productSpecifications.update(this.$route.params.id, data);
        this.$bvToast.toast('Спецификация успешно сохранена', {
          title: 'Успех',
          variant: 'success',
          solid: true,
        });
        await this.$router.push({ name: 'product-details', params: { id: this.specification.product_id } });
      } catch (modalError) {
        const errors = Object.values(modalError.response.data.details);

        this.$bvToast.toast(errors, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }
    },
    validate() {
      if (this.sizeOption && !this.form.size) {
        return 'Необходимо заполнить размер';
      }

      if (!this.form.qty) {
        return 'Количество не может быть пустым';
      }

      if (this.sizeOption && !this.form.label_size) {
        return 'Размер на бирке не может быть пустым';
      }
      let error = null;
      const requiredSlugs = this.specificationOptions
          .filter((option) => option.product_group_id === 5 && option.required)
          .map((option) => option.slug);

      if (requiredSlugs.length && (!this.form.measurements || !this.form.measurements.length)) {
        return 'Заполните все обязательные мерки';
      }

      if (requiredSlugs.length) {
        const keys = Object.values(requiredSlugs);
        const defaultMeasurements = { ...this.form.measurements[0].options };

        keys.forEach((slug) => {
          if (!defaultMeasurements[slug].value) {
            error = `${defaultMeasurements[slug].name} не может быть пустой`;
          }
        });
        if (error) {
          return error;
        }
      }

      return error;
    },
  },
  computed: {
    labelSize() {
      return this.specificationOptions.find((option) => option.slug === 'label_size');
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

</style>
